import { LimitAndRate, MaturityParams, CalculateInterest, MaturityData } from './types';
import { formatDecimal } from './calc';
import currency from 'currency.js';

const getLimitRatedata = (limitRate: LimitAndRate[], duration: number) => {
  // add default limitAndRate if limitAndRateProvided is less than investTill
  const limitRateList = [...limitRate];
  if (limitRateList.length < duration) {
    let difference = duration - limitRateList.length;
    while (difference > 0) {
      limitRateList.push({ limit: 0, rate: 0 }); // provide default value
      difference--;
    }
  }
  return limitRateList;
};

export const calculateInterest = ({
  investment,
  period,
  balance,
  interestRate, // not in percent
}: CalculateInterest) => {
  const perPayment = investment / period;
  const MONTHS_IN_YEAR = 12; // 12 months in a year
  const step = 12 / period;
  let curBalance = balance + perPayment;
  let interest = 0;
  for (let i = 0; i < MONTHS_IN_YEAR; i += step) {
    interest += (curBalance * interestRate * step) / MONTHS_IN_YEAR / 100; // 100 is percent factor
    curBalance += perPayment;
  }
  return interest;
};

const formatMaturityData = (item: MaturityData) => ({
  ...formatDecimal(item, 0), // no decimal
  rate: currency(item.rate).value, // format 2 decimal
});

export const getMaturityData = ({
  paymentType,
  investTill,
  limitAndRate,
  withdrawAt,
}: MaturityParams) => {
  const duration = investTill > withdrawAt ? investTill : withdrawAt;
  const limitAndRateData = getLimitRatedata(limitAndRate, duration);
  const maturitydata: MaturityData[] = [];
  for (let period = 0; period < duration; period++) {
    const prevBalance = period === 0 ? 0 : maturitydata[period - 1].endOfYearBalance;
    const { limit, rate } = limitAndRateData[period];
    const investment = period < investTill ? limit : 0; // no invest amount calculated if above investTill
    const interest = calculateInterest({
      investment,
      interestRate: rate,
      balance: prevBalance,
      period: paymentType,
    });
    const endOfYearBalance = prevBalance + investment + interest;
    maturitydata.push({ investment, interest, rate, endOfYearBalance });
  }

  const selectedMaturityData = maturitydata.slice(0, withdrawAt);

  const totalInvestment = selectedMaturityData
    .map((item) => item.investment)
    .reduce((sum, cur) => sum + cur, 0);

  const maturityAmount = currency(selectedMaturityData.slice(-1)[0].endOfYearBalance, {
    precision: 0,
  }).value;

  const totalInterest = maturityAmount - totalInvestment;

  return {
    maturityData: maturitydata.map(formatMaturityData) as MaturityData[],
    maturityAmount,
    totalInterest,
    totalInvestment,
  };
};
