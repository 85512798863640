import * as React from 'react';

export const CalculateButton: React.FC = () => (
  <button
    type="submit"
    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
  >
    Calculate
  </button>
);
