import React from 'react';
import { toCurrencyFormat } from 'src/utils/formatter';

interface CalculatorResultProps {
  title: string;
  fixedValue: number;
  inflationValue: number;
}

const CalculatorResult = ({ title, fixedValue, inflationValue }: CalculatorResultProps) => {
  return (
    <aside className="bg-white p-8 overflow-y-auto">
      <div className="space-y-6">
        <div>
          <h3 className="font-medium text-gray-900">{title}</h3>
          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Fixed</dt>
              <dd className="text-gray-900">{toCurrencyFormat(fixedValue, 2)}</dd>
            </div>

            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Inflation-Adjusted</dt>
              <dd className="text-gray-900">{toCurrencyFormat(inflationValue, 2)}</dd>
            </div>
          </dl>
        </div>
      </div>
    </aside>
  );
};

export default CalculatorResult;
