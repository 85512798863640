import classNames from 'classnames';

type TableProps = {
  columns: string[];
  data: Record<string, any>[];
  columnsStyle?: Record<string, string>;
};

const Table = ({ columns, data, columnsStyle }: TableProps) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column}
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((row, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`table${index}`}>
                    {Object.keys(row).map((col) => (
                      <td
                        className={classNames(
                          'px-6 py-4 whitespace-nowrap text-sm text-gray-900  text-center',
                          columnsStyle && columnsStyle[col] ? columnsStyle[col] : ''
                        )}
                      >
                        {row[col]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
