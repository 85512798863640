import React, { useState, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import { requiredInteger } from 'src/utils/schema';
import { ResultItem } from '../../components/ResultItem';
import { getMaturityData } from 'src/utils/maturity';
import { MaturityPaymentFrequency, MaturityData, LimitAndRate } from 'src/utils/types';
import { toCurrencyFormat } from 'src/utils/formatter';
import Swal from 'sweetalert2';
import InvestmentChart from './InvestmentChart';

const FREQUENCY_OPTIONS = ['Monthly', 'Quarterly', 'Half Yearly', 'Annually'] as const;

type FrequencyOptions = typeof FREQUENCY_OPTIONS[number];

type InvestmentForm = {
  customer_name: string;
  frequency: FrequencyOptions;
  invest_till: number;
  withdraw_at: number;
};

const getFrequencyValue = (option: FrequencyOptions) => {
  switch (option) {
    case 'Monthly':
      return MaturityPaymentFrequency.MONTHLY;
    case 'Quarterly':
      return MaturityPaymentFrequency.QUARTERLY;
    case 'Half Yearly':
      return MaturityPaymentFrequency.HALF_YEAR;
    default:
      return MaturityPaymentFrequency.ANNUALLY;
  }
};

const investmentSchema = yup
  .object({
    customer_name: yup.string().required('Customer name is a required field'),
    frequency: yup.string().required(),
    invest_till: requiredInteger,
    withdraw_at: requiredInteger,
  })
  .required();

const InvestmentMaturity: React.FC = () => {
  const [maturityData, setMaturityData] = useState<MaturityData[]>([]);
  const [limitAndRates, setLimitAndRates] = useState<LimitAndRate[]>([]);
  const [maturityAmount, setMaturityAmount] = useState<number>(0);
  const [totalInvestment, setTotalInvestment] = useState<number>(0);
  const [totalInterest, setTotalInterest] = useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<InvestmentForm>({
    resolver: yupResolver(investmentSchema),
  });

  const calculate = (data: InvestmentForm) => {
    const result = getMaturityData({
      paymentType: getFrequencyValue(data.frequency),
      investTill: data.invest_till,
      limitAndRate: limitAndRates,
      withdrawAt: data.withdraw_at,
    });

    const newMaturityData = result.maturityData.map((item, index) => {
      return {
        year: index + 1,
        limit: item.investment,
        investment: item.investment,
        rate: item.rate,
        endOfYearBalance: item.endOfYearBalance,
        interest: item.interest,
      };
    });
    const newLimitAndRates = result.maturityData.map((item) => {
      return {
        limit: item.investment,
        rate: item.rate,
      };
    });
    setLimitAndRates(newLimitAndRates);
    setMaturityData(newMaturityData);
    setMaturityAmount(result.maturityAmount);
    setTotalInvestment(result.totalInvestment);
    setTotalInterest(result.totalInterest);
  };

  const changeLimitRate = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const newMaturityData = maturityData.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value ? parseInt(value) : 0 };
      }
      return item;
    });

    const newLimitAndRates = limitAndRates.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value ? parseInt(value) : 0 };
      }
      return item;
    });

    setMaturityData(newMaturityData);
    setLimitAndRates(newLimitAndRates);
  };

  const changeAllLimitRate = (newLimitAndRates: LimitAndRate[]) => {
    const calculation = getMaturityData({
      paymentType: getFrequencyValue(getValues('frequency')),
      investTill: getValues('invest_till'),
      limitAndRate: newLimitAndRates,
      withdrawAt: getValues('withdraw_at'),
    });
    const newMaturityData = calculation.maturityData.map((item, index) => {
      return {
        year: index + 1,
        limit: item.investment,
        investment: item.investment,
        rate: item.rate,
        endOfYearBalance: item.endOfYearBalance,
        interest: item.interest,
      };
    });
    setLimitAndRates(newLimitAndRates);
    setMaturityData(newMaturityData);
    setMaturityAmount(calculation.maturityAmount);
    setTotalInvestment(calculation.totalInvestment);
    setTotalInterest(calculation.totalInterest);
  };

  const blurLimitRate = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const filledLimitAndRates = limitAndRates.filter((item) => (item as any)[name] > 0);
    if (filledLimitAndRates.length === 1) {
      Swal.fire({
        icon: 'info',
        title: `Do you want to fill all the ${name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#2C8A39',
      }).then((result) => {
        if (result.isConfirmed) {
          const newLimitAndRates = limitAndRates.map((item) => {
            return {
              ...item,
              [name]: value ? parseInt(value) : 0,
            };
          });
          changeAllLimitRate(newLimitAndRates);
        }
      });
    }
    calculate({
      customer_name: getValues('customer_name'),
      frequency: getValues('frequency'),
      invest_till: getValues('invest_till'),
      withdraw_at: getValues('withdraw_at'),
    });
  };

  const resetForm = () => {
    reset();
    setLimitAndRates([]);
    setMaturityData([]);
    setMaturityAmount(0);
    setTotalInvestment(0);
    setTotalInterest(0);
  };

  return (
    <div>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Investment Maturity</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(calculate)}>
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="pt-8">
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <Input
                    id="customerName"
                    className="sm:col-span-4 md:col-span-3"
                    label="Customer Name"
                    register={register('customer_name')}
                    errorMessage={errors.customer_name?.message}
                  />

                  <Select
                    id="frequency"
                    label="Frequency"
                    className="md:col-span-1 sm:col-span-2"
                    register={register('frequency')}
                    options={FREQUENCY_OPTIONS}
                  />

                  <Select
                    id="investTill"
                    label="Invest Till"
                    className="md:col-span-1 sm:col-span-2"
                    register={register('invest_till')}
                    options={[15, 20, 25, 30]}
                  />

                  <Select
                    id="withdrawAt"
                    label="Withdraw At"
                    className="md:col-span-1 sm:col-span-2"
                    register={register('withdraw_at')}
                    options={[15, 20, 25, 30]}
                  />
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Generate
                </button>
              </div>
            </div>
          </form>

          <div className="flex flex-col mt-10">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {['Year', 'Limit', 'Investment', 'Rate (%)', 'EOY Bal', 'Interest'].map(
                          (column) => (
                            <th
                              key={column}
                              scope="col"
                              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {column}
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {maturityData.map((item, index) => (
                        <tr key={`data-${index}`}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900  text-center">
                            {item.year}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            <input
                              type="text"
                              name="limit"
                              value={item.limit}
                              onChange={(e) => changeLimitRate(e, index)}
                              onBlur={blurLimitRate}
                              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900  text-center">
                            {toCurrencyFormat(item.investment)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900  text-center">
                            <input
                              type="text"
                              name="rate"
                              value={item.rate}
                              onChange={(e) => changeLimitRate(e, index)}
                              onBlur={blurLimitRate}
                              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900  text-center">
                            {toCurrencyFormat(item.endOfYearBalance)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900  text-center">
                            {toCurrencyFormat(item.interest)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-3 md:gap-6 mt-10">
            <div className="mt-5 md:mt-0 lg:col-span-2 md:col-span-3">
              <InvestmentChart
                totalInvestment={totalInvestment}
                totalInterest={totalInterest}
                maturityAmount={maturityAmount}
              />
            </div>
            <div className="md:col-span-1">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Result Summary</h3>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <ResultItem label="Maturity Amount" value={toCurrencyFormat(maturityAmount)} />
                    <ResultItem
                      label="Total Investment"
                      value={toCurrencyFormat(totalInvestment)}
                    />
                    <ResultItem label="Total Interest" value={toCurrencyFormat(totalInterest)} />
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default InvestmentMaturity;
