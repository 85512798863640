import currency from 'currency.js';

export const formatFinancial = (value: number) => {
  return '$' + Math.round(value).toLocaleString();
};

export const ucFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const splitCamelCase = (string: string) => string.replace(/([a-z])([A-Z])/g, '$1 $2');

export const formatName = (name: string) => ucFirst(splitCamelCase(name));

export const toCurrencyFormat = (value: number, precision = 0) =>
  currency(value, { precision }).format();
