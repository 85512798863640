import currency from 'currency.js';

export const calcExponential = (initNum: number, rate: number, atYear: number) => {
  return initNum * (1 + rate) ** atYear;
};

export const toPercentage = (x: number) => x / 100;

export const formatDecimal = (data: Record<string, number | number[]>, numOfDecimals = 2) => {
  const result: Record<string, number | number[]> = {};
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      result[key] = value.map((item) => currency(item, { precision: numOfDecimals }).value);
    } else {
      const curResult = currency(value, { precision: numOfDecimals }).value;
      // eslint-disable-next-line no-compare-neg-zero
      result[key] = curResult === -0 ? 0 : curResult;
    }
  }
  return result;
};
