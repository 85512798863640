import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import NavLinkHorizontal from './NavLinkHorizontal';
import NavLinkVertical from './NavLinkVertical';
import logo from '../assets/logo.png';

interface LayoutProps {
  navigation: Array<{
    name: string;
    href: string;
  }>;
}

const Layout = ({ navigation }: LayoutProps) => {
  return (
    <>
      <Disclosure>
        {({ open }) => (
          <div className="bg-white border-b border-gray-200">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <img className="block h-8 w-auto" src={logo} alt="Logo" />
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <NavLinkHorizontal key={item.name} to={item.href}>
                        {item.name}
                      </NavLinkHorizontal>
                    ))}
                  </div>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <NavLinkVertical key={item.name} to={item.href}>
                    {item.name}
                  </NavLinkVertical>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      <div className="py-10">
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
