import * as yup from 'yup';

export const requiredInteger = yup
  .number()
  .typeError('Must be a number')
  .min(0, 'Must be a positive number')
  .integer('Must be an integer')
  .required('This Field is required');

export const requiredPercentage = yup
  .number()
  .typeError('Must be a number')
  .min(0, 'Min value is 0')
  .max(100, 'Max value is 100')
  .required('This Field is required');

export const requiredMoney = yup
  .number()
  .typeError('Must be a number')
  .min(0, 'Must be a positive number')
  .required('This Field is required');

export const noMinRequiredPercentage = yup
  .number()
  .typeError('Must be a number')
  .required('This Field is required');
