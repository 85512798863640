import currency from 'currency.js';
import { toPercentage, formatDecimal } from './calc';
import {
  AnnuityData,
  GetAnnuityParam,
  PaymentType,
  PaymentOut,
  FutureValue,
  PresentValue,
  CalculatePayment,
  CalculatePrincipal,
  CalculateYearsToPayout,
  NumOfPeriods,
} from './types';

// calculate payment out with init principal
export const calculatePayout = ({
  rate,
  nper,
  pv,
  type = PaymentType.END_OF_PERIOD,
}: PaymentOut) => {
  let result = 0;

  if (type === PaymentType.END_OF_PERIOD) {
    result = pv / ((1 - 1 / (1 + rate) ** nper) / rate);
  } else {
    result = pv / ((1 - 1 / (1 + rate) ** (nper - 1)) / rate + 1);
  }

  return result;
};

// calculate FV with lumpsum
export const calculateFutureValue = ({ rate, nper, pv }: FutureValue) => {
  return pv * (1 + rate) ** nper;
};

// calculate PV with perodic payment
export const calculatePresentValue = ({ rate, type, payment, nper }: PresentValue) => {
  if (type === PaymentType.END_OF_PERIOD) {
    return payment * ((1 - 1 / (1 + rate) ** nper) / rate);
  }
  return payment * ((1 - 1 / (1 + rate) ** (nper - 1)) / rate) + payment;
};

// calculate nper
export const calculateNumOfPeriods = ({ rate, type, payment, pv }: NumOfPeriods) => {
  if (type === PaymentType.END_OF_PERIOD) {
    return -Math.log(1 - (pv / payment) * rate) / Math.log(1 + rate);
  }
  return -Math.log(1 + rate * (1 - pv / payment)) / Math.log(1 + rate) + 1;
};

export const getAnnuity = ({
  principal,
  annualInterestRate,
  yearsToPayOut,
  paymentFrequency,
  paymentType,
  annualInflationRate,
}: GetAnnuityParam) => {
  const ratePerPeriod = toPercentage(annualInterestRate / paymentFrequency);
  const inflationRatePerPeriod = toPercentage(annualInflationRate / paymentFrequency);
  const nper = yearsToPayOut * paymentFrequency;
  const initRate = (1 + ratePerPeriod) / (1 + inflationRatePerPeriod) - 1;
  const initPayout = calculatePayout({ rate: initRate, pv: principal, type: paymentType, nper });
  const annuityData: AnnuityData[] = [];

  for (let period = 0; period <= nper - paymentType; period++) {
    if (period === 0 && paymentType === PaymentType.BEGINING_OF_PERIOD) {
      annuityData.push({
        interestEarned: 0,
        payout: initPayout,
        balance: principal - initPayout,
        cumulativeInterest: 0,
      });
    } else if (period === 0 && paymentType === PaymentType.END_OF_PERIOD) {
      annuityData.push({ interestEarned: 0, payout: 0, balance: principal, cumulativeInterest: 0 });
    } else {
      const prevData = annuityData[period - 1];
      const interestEarned = prevData.balance * ratePerPeriod;
      const payout = calculateFutureValue({
        rate: inflationRatePerPeriod,
        nper: period,
        pv: initPayout,
      });
      const cumulativeInterest = prevData.cumulativeInterest + interestEarned;
      const balance = prevData.balance - payout + interestEarned;

      annuityData.push({
        interestEarned,
        payout,
        cumulativeInterest,
        balance,
      });
    }
  }

  return annuityData.map((item) => formatDecimal(item, 2)) as AnnuityData[];
};

const calculatePayment =
  ({ isAnnual = true } = {}) =>
  ({
    paymentType,
    annualInflationRate,
    annualInterestRate,
    startPrincipal,
    yearsToPayOut,
  }: CalculatePayment) => {
    const nperFactor = isAnnual ? 1 : 12;
    const interestRate = toPercentage(annualInterestRate) / nperFactor;
    const inflationRate = toPercentage(annualInflationRate) / nperFactor;
    const inflationAdjustedRate =
      ((1 + toPercentage(annualInterestRate)) / (1 + toPercentage(annualInflationRate)) - 1) /
      nperFactor;

    const payment = calculatePayout({
      rate: interestRate,
      nper: yearsToPayOut * nperFactor,
      pv: startPrincipal,
      type: paymentType,
    });

    const paymentWithInflation = (nper: number) =>
      calculatePayout({
        rate: inflationAdjustedRate,
        nper: yearsToPayOut * nperFactor,
        pv: startPrincipal,
        type: paymentType,
      }) *
      (1 + inflationRate) ** (nper - paymentType);

    const firstPaymentWithInflation = paymentWithInflation(1);

    const lastPaymentWithInflation = paymentWithInflation(yearsToPayOut * nperFactor);

    return {
      firstPayment: currency(payment).value,
      firstPaymentWithInflation: currency(firstPaymentWithInflation).value,
      lastPayment: currency(payment).value,
      lastPaymentWithInflation: currency(lastPaymentWithInflation).value,
    };
  };

export const calculateMonthlyPayment = calculatePayment({ isAnnual: false });

export const calculateAnnualPayment = calculatePayment();

export const calculatePrincipal = ({
  annualInflationRate,
  annualInterestRate,
  yearsToPayOut,
  firstAnnualPayment,
  paymentType,
}: CalculatePrincipal) => {
  const interestRate = toPercentage(annualInterestRate);
  const inflationRate = toPercentage(annualInflationRate);
  const inflationAdjustedRate = (1 + interestRate) / (1 + inflationRate) - 1;

  const principal = calculatePresentValue({
    rate: interestRate,
    nper: yearsToPayOut,
    payment: firstAnnualPayment,
    type: paymentType,
  });

  const payment =
    paymentType === PaymentType.BEGINING_OF_PERIOD
      ? firstAnnualPayment
      : firstAnnualPayment / (1 + inflationRate);

  const inflationAdjustedPrincipal = calculatePresentValue({
    rate: inflationAdjustedRate,
    nper: yearsToPayOut,
    payment,
    type: paymentType,
  });

  return {
    principal: currency(principal).value,
    inflationAdjustedPrincipal: currency(inflationAdjustedPrincipal).value,
  };
};

export const calculateYearsToPayout = ({
  annualInflationRate,
  annualInterestRate,
  startPrincipal,
  firstAnnualPayment,
  paymentType,
}: CalculateYearsToPayout) => {
  const interestRate = toPercentage(annualInterestRate);
  const inflationRate = toPercentage(annualInflationRate);
  const inflationAdjustedRate = (1 + interestRate) / (1 + inflationRate) - 1;

  const yearsToPayout = calculateNumOfPeriods({
    rate: interestRate,
    payment: firstAnnualPayment,
    type: paymentType,
    pv: startPrincipal,
  });

  const payment =
    paymentType === PaymentType.BEGINING_OF_PERIOD
      ? firstAnnualPayment
      : firstAnnualPayment / (1 + inflationRate);

  const inflationAdjustedYearsToPayout = calculateNumOfPeriods({
    rate: inflationAdjustedRate,
    payment,
    type: paymentType,
    pv: startPrincipal,
  });

  return {
    yearsToPayout: currency(yearsToPayout).value,
    inflationAdjustedYearsToPayout: currency(inflationAdjustedYearsToPayout).value,
  };
};
