import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CalculatorResult from 'src/components/CalculatorResult';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentType } from 'src/utils/types';
import { requiredInteger, requiredMoney, requiredPercentage } from 'src/utils/schema';
import Input from 'src/components/Input';
import { CalculateButton } from 'src/components/CalculateButton';
import { ResetButton } from 'src/components/ResetButton';
import { calculatePrincipal } from 'src/utils/annuity';
import { CalculatePrincipal, Principal } from 'src/utils/types';
import { ErrorMessage } from './types';

type Props = {
  customerName: string;
  paymentType: PaymentType;
  annualInflationRate: number;
  errorMessages: (p: ErrorMessage) => void;
};

type Form = CalculatePrincipal & {
  customerName: string;
};

const scheme = yup
  .object({
    firstAnnualPayment: requiredMoney,
    annualInterestRate: requiredPercentage,
    yearsToPayOut: requiredInteger,
    customerName: yup.string().required('This field is required'),
    PaymentType: yup.string(),
    annualInflationRate: requiredPercentage,
  })
  .required();

const StartingPrincipal: React.FC<Props> = ({
  customerName,
  paymentType,
  annualInflationRate,
  errorMessages,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(scheme),
  });
  const [principal, setPrincipal] = React.useState<Principal>();

  const onSubmit = (data: Form) => {
    const res = calculatePrincipal(data);
    setPrincipal(res);
  };

  const onResetClick = React.useCallback(() => {
    resetField('annualInterestRate');
    resetField('firstAnnualPayment');
    resetField('yearsToPayOut');
  }, [resetField]);

  React.useEffect(() => {
    errorMessages({
      customerName: errors.customerName?.message,
      annualInflationRate: errors.annualInflationRate?.message,
    });
  }, [errorMessages, errors.customerName?.message, errors.annualInflationRate?.message]);

  React.useEffect(() => {
    setValue('customerName', customerName);
  }, [customerName, setValue]);

  React.useEffect(() => {
    setValue('paymentType', paymentType);
  }, [paymentType, setValue]);

  React.useEffect(() => {
    setValue('annualInflationRate', annualInflationRate);
  }, [annualInflationRate, setValue]);

  return (
    <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Solve for Starting Principal</h3>
      <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <Input
          id="principalFirstAnnualPayment"
          label="First Annual Payment"
          register={register('firstAnnualPayment')}
          errorMessage={errors.firstAnnualPayment?.message}
          className="sm:col-span-2"
        />
        <Input
          id="paymentAnnualInterest"
          label="Annual Interest Rate (i)"
          register={register('annualInterestRate')}
          errorMessage={errors.annualInterestRate?.message}
          className="sm:col-span-2"
          trailingText="%"
        />
        <Input
          id="paymentYearsPayout"
          label="Years to Pay Out (n)"
          register={register('yearsToPayOut')}
          errorMessage={errors.yearsToPayOut?.message}
          className="sm:col-span-2"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-y-6 gap-x-4 items-center justify-end">
        <CalculatorResult
          title="Starting Principal"
          fixedValue={principal?.principal ?? 0}
          inflationValue={principal?.inflationAdjustedPrincipal ?? 0}
        />
        <div className="flex h-10 sm:col-start-2 md:col-start-4 justify-end">
          <ResetButton onClick={onResetClick} />
          <CalculateButton />
        </div>
      </div>
    </form>
  );
};

export default React.memo(StartingPrincipal);
