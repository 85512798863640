import classNames from 'classnames';
import * as React from 'react';

type Props = {
  id: string;
  label: string;
  register?: Record<string, any>;
  errorMessage?: string;
  leadingText?: string;
  trailingText?: string;
  className?: string;
  ariaDescription?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
};

const Input: React.FC<Props> = ({
  id,
  label,
  register = {},
  errorMessage,
  leadingText,
  trailingText,
  className = '',
  ariaDescription = '',
  value,
  onChange,
}) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        {leadingText && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{leadingText}</span>
          </div>
        )}
        <input
          id={id}
          type="text"
          {...register}
          className={classNames(
            leadingText ? 'pl-7' : '',
            'focus:ring-primary-500 focus:border-primary-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md'
          )}
          aria-describedby={ariaDescription}
          onChange={onChange}
          value={value}
        />
        {trailingText && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id={`${id}-price-currency`}>
              {trailingText}
            </span>
          </div>
        )}
      </div>
      <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
    </div>
  );
};

export default Input;
