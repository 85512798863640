import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import AnnuityTabs from './pages/Annuity/AnnuityTabs';
import InvestmentMaturity from './pages/Investment/InvestmentMaturity';
import RetirementPlanner from './pages/Retirement/RetirementPlanner';
import NotFound from './pages/NotFound';

type Navigation = Array<{
  name: string;
  href: string;
}>;

const App: React.FC = () => {
  const navigation: Navigation = [
    { name: 'Retirement Planner', href: 'retirement-planner' },
    { name: 'Annuity Calculator', href: 'annuity-calculator' },
    { name: 'Investment Maturity', href: 'investment-maturity' },
  ];

  return (
    <div className="min-h-full">
      <Routes>
        <Route path="/" element={<Layout navigation={navigation} />}>
          <Route index element={<Navigate to="/retirement-planner" />} />
          <Route path="retirement-planner" element={<RetirementPlanner />} />
          <Route path="investment-maturity" element={<InvestmentMaturity />} />
          <Route path="annuity-calculator" element={<AnnuityTabs />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
