import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Table from 'src/components/Table';
import { requiredInteger, requiredPercentage, requiredMoney } from 'src/utils/schema';
import { toCurrencyFormat } from 'src/utils/formatter';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import { AnnuityData, PaymentFrequency, PaymentType } from 'src/utils/types';
import { getAnnuity } from 'src/utils/annuity';
import AnnuityBarChart from './AnnuityBarChart';
import { CalculateButton } from 'src/components/CalculateButton';
import { ResetButton } from 'src/components/ResetButton';
import { ResultItem } from '../../components/ResultItem';

const PAYMENT_FREQUENCY_OPTIONS = [
  'Bi-Weekly',
  'Monthly',
  'Bi-Monthly',
  'Semi-Monthly',
  'Quarterly',
  'Semi-Annually',
  'Annually',
] as const;

export const PAYMENT_TYPE_OPTIONS = ['Beginning of Period', 'End of Period'] as const;

type PaymentTypeFrequencyOptions = typeof PAYMENT_FREQUENCY_OPTIONS[number];

export type PaymentTypeOptions = typeof PAYMENT_TYPE_OPTIONS[number];

type AnnualForm = {
  starting_principal: number;
  annual_interest_rate: number;
  starting_year: number;
  years_to_pay_out: number;
  payment_frequency: PaymentTypeFrequencyOptions;
  payment_type: PaymentTypeOptions;
  annual_inflation_rate: number;
};

const getPaymentFrequencyValue = (option: PaymentTypeFrequencyOptions) => {
  switch (option) {
    case 'Semi-Monthly':
      return PaymentFrequency.SEMI_MONTHLY;
    case 'Bi-Monthly':
      return PaymentFrequency.BI_MONTHLY;
    case 'Bi-Weekly':
      return PaymentFrequency.BI_WEEKLY;
    case 'Monthly':
      return PaymentFrequency.MONTHLY;
    case 'Quarterly':
      return PaymentFrequency.QUARTERLY;
    case 'Semi-Annually':
      return PaymentFrequency.SEMI_ANNUALLY;
    default:
      return PaymentFrequency.ANNUALLY;
  }
};

export const getPaymentTypevalue = (option: PaymentTypeOptions) => {
  if (option === 'Beginning of Period') {
    return PaymentType.BEGINING_OF_PERIOD;
  }
  return PaymentType.END_OF_PERIOD;
};

const formatAnnuitydata = (data: AnnuityData[]) =>
  data.map((item, index) => ({
    index,
    interest: toCurrencyFormat(item.interestEarned, 2),
    payout: toCurrencyFormat(item.payout, 2),
    balance: toCurrencyFormat(item.balance, 2),
    cumulativeInterest: toCurrencyFormat(item.cumulativeInterest, 2),
  }));

const getInitialPayout = (data: AnnuityData[]) => {
  if (data.length === 0) return '0';
  return toCurrencyFormat(data[0].payout !== 0 ? data[0].payout : data[1].payout, 2);
};

const getFinalPayout = (data: AnnuityData[]) => {
  if (data.length === 0) return '0';
  return toCurrencyFormat(data[data.length - 1].payout, 2);
};

const getTotalInterestEarned = (data: AnnuityData[]) => {
  if (data.length === 0) return '0';
  return toCurrencyFormat(data[data.length - 1].cumulativeInterest, 2);
};

const annualSchema = yup
  .object({
    starting_principal: requiredMoney,
    annual_interest_rate: requiredPercentage,
    starting_year: requiredInteger,
    years_to_pay_out: requiredInteger,
    payment_frequency: yup.string().required(),
    payment_type: yup.string().required(),
    annual_inflation_rate: requiredPercentage,
  })
  .required();

const Annuity: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AnnualForm>({
    resolver: yupResolver(annualSchema),
  });
  const [annuityData, setAnnuityData] = React.useState<AnnuityData[]>([]);
  const barWidth = React.useRef(0);
  const startFrom = React.useRef(0);

  const onSubmit = (data: AnnualForm) => {
    barWidth.current = 40 / getPaymentFrequencyValue(data.payment_frequency);
    startFrom.current = data.starting_year;
    const result = getAnnuity({
      annualInflationRate: data.annual_inflation_rate,
      annualInterestRate: data.annual_interest_rate,
      principal: data.starting_principal,
      yearsToPayOut: data.years_to_pay_out,
      paymentFrequency: getPaymentFrequencyValue(data.payment_frequency),
      paymentType: getPaymentTypevalue(data.payment_type),
    });
    setAnnuityData(result);
  };

  const onResetClick = React.useCallback(() => {
    setAnnuityData([]);
    reset();
  }, [reset]);

  return (
    <div className="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Withdrawal Plan</h3>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <Input
                  id="startPrincipal"
                  className="sm:col-span-3 md:col-span-2"
                  label="Starting Principal (P)"
                  leadingText="$"
                  trailingText="SGD"
                  register={register('starting_principal')}
                  errorMessage={errors.starting_principal?.message}
                />
                <Input
                  id="annualInterestRate"
                  className="sm:col-span-3 md:col-span-2"
                  label="Annual Interest Rate (i)"
                  trailingText="%"
                  register={register('annual_interest_rate')}
                  errorMessage={errors.annual_interest_rate?.message}
                />
                <Input
                  id="startingYear"
                  className="sm:col-span-3 md:col-span-2"
                  label="Starting Year"
                  register={register('starting_year')}
                  errorMessage={errors.starting_year?.message}
                />
                <Input
                  id="yearsToPayout"
                  className="sm:col-span-3 md:col-span-2"
                  label="Years to Pay Out (n)"
                  register={register('years_to_pay_out')}
                  errorMessage={errors.years_to_pay_out?.message}
                />
                <Select
                  id="paymentFrequency"
                  label="Payment Frequency"
                  className="sm:col-span-3 md:col-span-2"
                  register={register('payment_frequency')}
                  options={PAYMENT_FREQUENCY_OPTIONS}
                />
                <Select
                  id="paymentType"
                  label="Payment Type"
                  className="sm:col-span-3 md:col-span-2"
                  register={register('payment_type')}
                  options={PAYMENT_TYPE_OPTIONS}
                />
                <Input
                  id="annualInflationRate"
                  className="sm:col-span-3 md:col-span-2"
                  label="Annual Inflation Rate (g)"
                  register={register('annual_inflation_rate')}
                  errorMessage={errors.annual_inflation_rate?.message}
                />
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <ResetButton onClick={onResetClick} />
                <CalculateButton />
              </div>
            </div>
          </form>
        </div>

        <div className="md:col-span-1">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Result</h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <ResultItem label="Initial Payout" value={getInitialPayout(annuityData)} />
                <ResultItem label="Final Payout" value={getFinalPayout(annuityData)} />
                <ResultItem
                  label="Total Interest Earned"
                  value={getTotalInterestEarned(annuityData)}
                />
              </dl>
            </div>
          </div>
        </div>
      </div>

      {annuityData.length > 0 && (
        <>
          <div className="container lg:px-10 md:px-5 sm:px-1 mt-10">
            <AnnuityBarChart
              annuityData={annuityData}
              startFrom={startFrom.current}
              barWidth={barWidth.current}
            />
          </div>

          <div className="mt-10">
            <h4 className="text-md leading-6 font-medium text-gray-900 mb-2">Payout Schedule</h4>
            <Table
              columns={['#', 'Interest', 'Payout', 'Balance', 'Cumulative Interest']}
              data={formatAnnuitydata(annuityData)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Annuity;
