import * as React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { toCurrencyFormat } from 'src/utils/formatter';

interface Props {
  totalInvestment: number;
  totalInterest: number;
  maturityAmount: number;
}

const InvestmentChart: React.FC<Props> = ({ totalInvestment, totalInterest, maturityAmount }) => {
  const data = [
    {
      name: 'Total Investment',
      value: totalInvestment,
      amt: totalInvestment,
    },
    {
      name: 'Total Interest',
      value: [totalInvestment, maturityAmount],
      amt: totalInterest,
    },
    {
      name: 'Maturity Amount',
      value: maturityAmount,
      amt: maturityAmount,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={320}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          dataKey="amt"
          type="number"
          tickCount={10}
          tickFormatter={(value: number) => toCurrencyFormat(value)}
          domain={[0, (dataMax: number) => dataMax + 200000]}
        />
        <Bar dataKey="value" fill="#6ED17C">
          <LabelList
            dataKey="amt"
            position="top"
            formatter={(value: number) => toCurrencyFormat(value)}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default InvestmentChart;
