export enum PaymentFrequency {
  BI_WEEKLY = 26, // payment freq per year
  SEMI_MONTHLY = 24,
  MONTHLY = 12,
  BI_MONTHLY = 6,
  QUARTERLY = 4,
  SEMI_ANNUALLY = 2,
  ANNUALLY = 1,
}

export enum PaymentType {
  END_OF_PERIOD = 0,
  BEGINING_OF_PERIOD = 1,
}

export type GetAnnuityParam = {
  principal: number;
  annualInterestRate: number;
  yearsToPayOut: number;
  paymentFrequency: PaymentFrequency;
  paymentType: PaymentType;
  annualInflationRate: number;
};

export type AnnuityData = {
  interestEarned: number;
  payout: number; // withdrawal
  balance: number;
  cumulativeInterest: number;
};

export type PaymentOut = {
  rate: number;
  nper: number;
  pv: number;
  type?: PaymentType;
};

export type FutureValue = {
  rate: number;
  nper: number;
  pv: number;
};

export type PresentValue = {
  rate: number;
  payment: number;
  nper: number;
  type: PaymentType;
};

export type NumOfPeriods = {
  rate: number;
  payment: number;
  pv: number;
  type: PaymentType;
};

export type CurrentData = {
  currentAge: number;
  annualIncome: number;
  annualIncomeIncrease: number;
  retirementSavingsBalance: number;
  annualSavingsAmount: number;
  annualSavingsIncrease: number;
  investmentReturns: number;
};

export type RetirementData = {
  annualPensionBenefit: number;
  annualPensionBenefitIncrease: number;
  desiredRetirementAge: number;
  numberOfYearsOfRetirementIncome: number;
  incomeReplacement: number;
  investmentReturn: number;
};

export type Uncertainty = {
  investmentReturn: number;
  annualSavingsAmount: number;
  annualSavingsIncreases: number;
  annualPensionBenefitAmount: number;
  annualPensionBenefitIncreases: number;
};

export type FinancialData = {
  age: number;
  salary: number;
  balance: number;
  balanceUncertainty: number[];
  interest: number;
  interestUncertainty: number[];
  savings: number;
  savingsUncertainty: number[];
  retirementIncome: number;
  pensionIncome: number;
  pensionUncertainty: number[];
  yearEndBalance: number;
  yearEndBalanceUncertainty: number[];
};

export type GetRetirementData = {
  current: CurrentData;
  retirement: RetirementData;
  uncertainty: Uncertainty;
};

export enum MaturityPaymentFrequency {
  MONTHLY = 12,
  QUARTERLY = 4,
  HALF_YEAR = 2,
  ANNUALLY = 1,
}

export type MaturityData = {
  year?: number;
  limit?: number;
  investment: number;
  rate: number;
  endOfYearBalance: number;
  interest: number;
};

export type LimitAndRate = {
  limit: number;
  rate: number;
};

export type MaturityParams = {
  paymentType: MaturityPaymentFrequency;
  investTill: number;
  limitAndRate: LimitAndRate[];
  withdrawAt: number;
};

export type CalculateInterest = {
  investment: number;
  balance: number;
  period: MaturityPaymentFrequency;
  interestRate: number;
};

type AnnualCalculator = {
  paymentType: PaymentType;
  annualInflationRate: number;
  annualInterestRate: number;
};

export type CalculatePayment = AnnualCalculator & {
  startPrincipal: number;
  yearsToPayOut: number;
};

export type CalculatePrincipal = AnnualCalculator & {
  firstAnnualPayment: number;
  yearsToPayOut: number;
};

export type CalculateYearsToPayout = AnnualCalculator & {
  firstAnnualPayment: number;
  startPrincipal: number;
};

export type AnnualPayment = {
  firstPayment: number;
  firstPaymentWithInflation: number;
  lastPayment: number;
  lastPaymentWithInflation: number;
};

export type Principal = {
  principal: number;
  inflationAdjustedPrincipal: number;
};

export type YearsToPayout = {
  yearsToPayout: number;
  inflationAdjustedYearsToPayout: number;
};
