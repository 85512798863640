import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Table from 'src/components/Table';
import {
  requiredInteger,
  requiredPercentage,
  requiredMoney,
  noMinRequiredPercentage,
} from 'src/utils/schema';
import { toCurrencyFormat } from 'src/utils/formatter';
import { getRetirementData } from 'src/utils/retirement';
import { FinancialData, CurrentData, RetirementData, Uncertainty } from 'src/utils/types';
import Input from 'src/components/Input';
import { CalculateButton } from 'src/components/CalculateButton';
import { ResetButton } from 'src/components/ResetButton';
import RetirementChart from './RetirementChart';

interface FormInputs {
  customer_name: string;
  age: number;
  annual_income: number;
  annual_inflation: number;
  retirement_savings: number;
  annual_savings_amount: number;
  annual_savings_increases: number;
  investment_return_now: number;

  annual_pension_benefit: number;
  annual_pension_benefit_increases: number;
  desired_retirement_age: number;
  number_of_years_of_retirement_income: number;
  income_replacement: number;
  investment_return_retirement: number;

  investment_return_uncertainty: number;
  annual_savings_amount_uncertainty: number;
  annual_savings_increases_uncertainty: number;
  annual_pension_benefit_amount_uncertainty: number;
  annual_pension_penefit_increases_uncertainty: number;
}

const schema = yup
  .object({
    customer_name: yup.string().required('This field is required'),
    age: requiredInteger,

    annual_income: requiredMoney,
    annual_inflation: noMinRequiredPercentage,
    retirement_savings: requiredMoney,
    annual_savings_amount: requiredMoney,
    annual_savings_increases: requiredPercentage,
    investment_return_now: noMinRequiredPercentage,

    annual_pension_benefit: requiredMoney,
    annual_pension_benefit_increases: requiredPercentage,
    desired_retirement_age: requiredInteger,
    number_of_years_of_retirement_income: requiredInteger,
    income_replacement: requiredPercentage,
    investment_return_retirement: noMinRequiredPercentage,

    investment_return_uncertainty: requiredPercentage,
    annual_savings_amount_uncertainty: requiredPercentage,
    annual_savings_increases_uncertainty: requiredPercentage,
    annual_pension_benefit_amount_uncertainty: requiredPercentage,
    annual_pension_penefit_increases_uncertainty: requiredPercentage,
  })
  .required();

const renderTextSummary = (data: FinancialData[]) => {
  const { yearEndBalance, age } = data[data.length - 1];
  if (yearEndBalance > 0) {
    return (
      <p className="my-5 text-green-600 text-lg">
        Congratulations! At age {age}, you will have a savings balance of{' '}
        {toCurrencyFormat(yearEndBalance)}
      </p>
    );
  }
  const index = data.findIndex((item) => item.yearEndBalance < 0); // find index which yearEndBalance is minus
  const lastAge = data[index - 1].age;

  return (
    <p className="my-5 text-red-500 text-lg">
      OOPS! This plan will only provide income to age {lastAge}. You'll need to make some
      adjustments
    </p>
  );
};

const formatCurrency = (data: FinancialData[]) =>
  data.map((item) => ({
    age: item.age,
    salary: toCurrencyFormat(item.salary),
    balance: toCurrencyFormat(item.balance),
    interest: toCurrencyFormat(item.interest),
    yearlySavings: toCurrencyFormat(item.savings),
    retirementIncome: toCurrencyFormat(item.retirementIncome),
    pensionIncome: toCurrencyFormat(item.pensionIncome),
    yearEndBalance: toCurrencyFormat(item.yearEndBalance),
  }));

const RetirementPlanner: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });
  const [financialData, setFinancialData] = React.useState<FinancialData[]>([]);
  const currentAge = React.useRef(0);
  const retirementData = React.useRef<RetirementData | null>(null);

  const onSubmit = (data: FormInputs) => {
    const current: CurrentData = {
      currentAge: data.age,
      annualIncome: data.annual_income,
      retirementSavingsBalance: data.retirement_savings,
      annualSavingsAmount: data.annual_savings_amount,
      annualSavingsIncrease: data.annual_savings_increases,
      annualIncomeIncrease: data.annual_inflation,
      investmentReturns: data.investment_return_now,
    };
    const retirement: RetirementData = {
      annualPensionBenefit: data.annual_pension_benefit,
      annualPensionBenefitIncrease: data.annual_pension_benefit_increases,
      desiredRetirementAge: data.desired_retirement_age,
      numberOfYearsOfRetirementIncome: data.number_of_years_of_retirement_income,
      incomeReplacement: data.income_replacement,
      investmentReturn: data.investment_return_retirement,
    };
    const uncertainty: Uncertainty = {
      investmentReturn: data.investment_return_uncertainty,
      annualSavingsAmount: data.annual_savings_amount_uncertainty,
      annualSavingsIncreases: data.annual_savings_increases_uncertainty,
      annualPensionBenefitAmount: data.annual_pension_benefit_amount_uncertainty,
      annualPensionBenefitIncreases: data.annual_pension_penefit_increases_uncertainty,
    };
    currentAge.current = data.age;
    retirementData.current = retirement;
    setFinancialData(getRetirementData({ current, retirement, uncertainty }));
  };

  const onResetClick = React.useCallback(() => {
    setFinancialData([]);
    reset();
  }, [reset]);

  return (
    <div>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Retirement Planner</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto mt-5 px-6 lg:px-8">
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <Input
                  id="customerName"
                  className="sm:col-span-4 md:col-span-3"
                  label="Customer Name"
                  register={register('customer_name')}
                  errorMessage={errors.customer_name?.message}
                />
                <Input
                  id="currentAge"
                  className="md:col-span-1 sm:col-span-2"
                  label="Your Current Age"
                  register={register('age')}
                  errorMessage={errors.age?.message}
                />
              </div>

              <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
                <div className="pt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Now</h3>
                  <div className="mt-6 space-y-4">
                    <Input
                      label="Annual Income"
                      id="annualIncome"
                      register={register('annual_income')}
                      leadingText="$"
                      trailingText="SGD"
                      errorMessage={errors.annual_income?.message}
                    />
                    <Input
                      label="Annual Inflation & Income Increases"
                      id="annualInflation"
                      register={register('annual_inflation')}
                      trailingText="%"
                      errorMessage={errors.annual_inflation?.message}
                    />
                    <Input
                      label="Retirement savings balance"
                      id="retirementSavings"
                      register={register('retirement_savings')}
                      leadingText="$"
                      trailingText="SGD"
                      errorMessage={errors.retirement_savings?.message}
                    />
                    <Input
                      label="Annual savings amount"
                      id="annualSavingAmount"
                      register={register('annual_savings_amount')}
                      leadingText="$"
                      trailingText="SGD"
                      errorMessage={errors.annual_savings_amount?.message}
                    />
                    <Input
                      label="Annual savings increases"
                      id="annualSavingIncrease"
                      register={register('annual_savings_increases')}
                      trailingText="%"
                      errorMessage={errors.annual_savings_increases?.message}
                    />
                    <Input
                      label="Investment return"
                      id="annualSavingIncrease"
                      register={register('investment_return_now')}
                      trailingText="%"
                      errorMessage={errors.investment_return_now?.message}
                    />
                  </div>
                </div>

                <div className="pt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">At Retirement</h3>
                  <div className="mt-6 space-y-4">
                    <Input
                      label="Annual pension benefit"
                      id="annualPensionBenefit"
                      register={register('annual_pension_benefit')}
                      leadingText="$"
                      trailingText="SGD"
                      errorMessage={errors.annual_pension_benefit?.message}
                    />
                    <Input
                      label="Annual pension benefit increases"
                      id="annualPensionIncrease"
                      register={register('annual_pension_benefit_increases')}
                      trailingText="%"
                      errorMessage={errors.annual_pension_benefit_increases?.message}
                    />
                    <Input
                      label="Desired retirement age"
                      id="desiredRetirementAge"
                      register={register('desired_retirement_age')}
                      errorMessage={errors.desired_retirement_age?.message}
                    />
                    <Input
                      label="Number of years of retirement income"
                      id="numOfRetirementYears"
                      register={register('number_of_years_of_retirement_income')}
                      errorMessage={errors.number_of_years_of_retirement_income?.message}
                    />
                    <Input
                      label="Income replacement"
                      id="incomeReplacement"
                      register={register('income_replacement')}
                      trailingText="%"
                      errorMessage={errors.income_replacement?.message}
                    />
                    <Input
                      label="Investment Return"
                      id="investmentReturnRetirement"
                      register={register('investment_return_retirement')}
                      trailingText="%"
                      errorMessage={errors.investment_return_retirement?.message}
                    />
                  </div>
                </div>

                <div className="pt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Uncertainty (%)</h3>
                  <div className="mt-6 space-y-4">
                    <Input
                      label="Investment return uncertainty"
                      id="investmentReturnUncertainty"
                      register={register('investment_return_uncertainty')}
                      trailingText="%"
                      errorMessage={errors.investment_return_uncertainty?.message}
                    />
                    <Input
                      label="Annual savings amount uncertainty"
                      id="annualSavingsAmountUncertainty"
                      register={register('annual_savings_amount_uncertainty')}
                      trailingText="%"
                      errorMessage={errors.annual_savings_amount_uncertainty?.message}
                    />
                    <Input
                      label="Annual savings increases uncertainty"
                      id="annualSavingsIncreaseUncertainty"
                      register={register('annual_savings_increases_uncertainty')}
                      trailingText="%"
                      errorMessage={errors.annual_savings_increases_uncertainty?.message}
                    />
                    <Input
                      label="Annual pension benefit amount uncertainty"
                      id="annualPensionBenefitAmountUncertainty"
                      register={register('annual_pension_benefit_amount_uncertainty')}
                      trailingText="%"
                      errorMessage={errors.annual_pension_benefit_amount_uncertainty?.message}
                    />
                    <Input
                      label="Annual Pension benefit increases uncertainty"
                      id="annualPensionBenefitIncreasesUncertainty"
                      register={register('annual_pension_penefit_increases_uncertainty')}
                      trailingText="%"
                      errorMessage={errors.annual_pension_penefit_increases_uncertainty?.message}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex pt-5 justify-end">
              <ResetButton onClick={onResetClick} />
              <CalculateButton />
            </div>
          </form>

          {financialData.length > 0 && (
            <>
              {renderTextSummary(financialData)}
              <div className="container lg:px-10 md:px-5 sm:px-1 mt-5">
                <RetirementChart
                  financialData={financialData}
                  currentAge={currentAge.current}
                  retirementData={retirementData.current as RetirementData}
                />
              </div>

              <div className="mt-10">
                <Table
                  columns={[
                    'Age',
                    'Salary',
                    'Balance',
                    'Interest',
                    'Yearly savings',
                    'Desired retirement income',
                    'Pension income',
                    'Year ending balance',
                  ]}
                  data={formatCurrency(financialData)}
                  columnsStyle={{ age: 'font-medium' }}
                />
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default RetirementPlanner;
