import * as React from 'react';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip } from 'recharts';
import { AnnuityData } from 'src/utils/types';
import { formatFinancial, formatName } from 'src/utils/formatter';

type Props = {
  annuityData: AnnuityData[];
  startFrom: number;
  barWidth: number;
};

const tickFormatter = (value: number) => formatFinancial(value);

const addIndex = (data: AnnuityData[]) => data.map((item, index) => ({ index, ...item }));

const labelFormatter = (value: string) => 'Year: ' + value;

const formatTooltip = (value: number, name: string) => {
  return [formatFinancial(value), formatName(name)];
};

const AnnuityBarChart: React.FC<Props> = ({ annuityData, startFrom, barWidth }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={addIndex(annuityData).slice(startFrom)}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="index"
          interval="preserveStartEnd"
          label={{ value: 'Year', offset: -5, position: 'insideBottom' }}
        />
        <YAxis
          allowDataOverflow
          allowDecimals={false}
          interval="preserveStartEnd"
          tickFormatter={tickFormatter}
          type="number"
          width={100}
          label={{ value: 'Balance', angle: -90, position: 'insideLeft' }}
        />
        <Tooltip formatter={formatTooltip} labelFormatter={labelFormatter} />
        <Bar dataKey="balance" fill="#82ca9d" barSize={barWidth} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(AnnuityBarChart);
