import * as React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { Disclosure } from '@headlessui/react';

const NavLinkVertical = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Disclosure.Button
      as={Link}
      to={to}
      className={classNames(
        match
          ? 'bg-primary-50 border-primary-500 text-primary-700'
          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
      )}
      aria-current={match ? 'page' : undefined}
      {...props}
    >
      {children}
    </Disclosure.Button>
  );
};

export default NavLinkVertical;
