import React, { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import Calculator from './AnnuityCalculator';
import Annuity from './AnnuityTable';

type Tabs = Array<{
  name: string;
  href: string;
  current: boolean;
  element: React.ReactNode;
}>;

const AnnuityCalculator: React.FC = () => {
  const tabs: Tabs = [
    { name: 'Annuity', href: '#', current: true, element: <Annuity /> },
    { name: 'Calculator', href: '#', current: false, element: <Calculator /> },
  ];
  return (
    <div>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">Annuity Calculator</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <Tab.Group>
            <Tab.List
              as="nav"
              className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
            >
              {tabs.map((tab, tabIndex) => (
                <Tab as={Fragment} key={tab.name}>
                  {({ selected }) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      className={classNames(
                        selected ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                        tabIndex === 0 ? 'rounded-l-lg' : '',
                        tabIndex === tabs.length - 1 ? 'rounded-r-lg' : '',
                        'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                      )}
                      aria-current={selected ? 'page' : undefined}
                    >
                      <span>{tab.name}</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          selected ? 'bg-primary-500' : 'bg-transparent',
                          'absolute inset-x-0 bottom-0 h-0.5'
                        )}
                      />
                    </a>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {tabs.map((tab) => (
                <Tab.Panel key={tab.name}>{tab.element}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </main>
    </div>
  );
};

export default AnnuityCalculator;
