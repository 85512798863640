import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CalculatorResult from 'src/components/CalculatorResult';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentType, AnnualPayment as AnnualPaymentType } from 'src/utils/types';
import { requiredInteger, requiredMoney, requiredPercentage } from 'src/utils/schema';
import Input from 'src/components/Input';
import { CalculateButton } from 'src/components/CalculateButton';
import { ResetButton } from 'src/components/ResetButton';
import { calculateAnnualPayment, calculateMonthlyPayment } from 'src/utils/annuity';
import { ErrorMessage } from './types';

type Props = {
  customerName: string;
  paymentType: PaymentType;
  annualInflationRate: number;
  errorMessages: (p: ErrorMessage) => void;
};

type Form = Omit<Props, 'errorMessages'> & {
  startingPrincipal: number;
  annualInterestRate: number;
  yearsToPayout: number;
};

const scheme = yup
  .object({
    startingPrincipal: requiredMoney,
    annualInterestRate: requiredPercentage,
    yearsToPayout: requiredInteger,
    customerName: yup.string().required('This field is required'),
    PaymentType: yup.string(),
    annualInflationRate: requiredPercentage,
  })
  .required();

const AnnualPayment: React.FC<Props> = ({
  customerName,
  paymentType,
  annualInflationRate,
  errorMessages,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(scheme),
  });
  const [annual, setAnnual] = React.useState<AnnualPaymentType>();
  const [monthly, setMonthly] = React.useState<AnnualPaymentType>();

  const onSubmit = (data: Form) => {
    setAnnual(
      calculateAnnualPayment({
        paymentType: data.paymentType,
        annualInflationRate: data.annualInflationRate,
        annualInterestRate: data.annualInterestRate,
        startPrincipal: data.startingPrincipal,
        yearsToPayOut: data.yearsToPayout,
      })
    );
    setMonthly(
      calculateMonthlyPayment({
        paymentType: data.paymentType,
        annualInflationRate: data.annualInflationRate,
        annualInterestRate: data.annualInterestRate,
        startPrincipal: data.startingPrincipal,
        yearsToPayOut: data.yearsToPayout,
      })
    );
  };

  const onResetClick = React.useCallback(() => {
    resetField('annualInterestRate');
    resetField('startingPrincipal');
    resetField('yearsToPayout');
    setAnnual(undefined);
    setMonthly(undefined);
  }, [resetField]);

  React.useEffect(() => {
    errorMessages({
      customerName: errors.customerName?.message,
      annualInflationRate: errors.annualInflationRate?.message,
    });
  }, [errorMessages, errors.customerName?.message, errors.annualInflationRate?.message]);

  React.useEffect(() => {
    setValue('customerName', customerName);
  }, [customerName, setValue]);

  React.useEffect(() => {
    setValue('paymentType', paymentType);
  }, [paymentType, setValue]);

  React.useEffect(() => {
    setValue('annualInflationRate', annualInflationRate);
  }, [annualInflationRate, setValue]);

  return (
    <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-lg leading-6 font-medium text-gray-900">Solve for Annual PAYMENT</h3>
      <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <Input
          id="paymentStartingPrinciple"
          label="Starting Principal (P)"
          register={register('startingPrincipal')}
          errorMessage={errors.startingPrincipal?.message}
          className="sm:col-span-2"
        />
        <Input
          id="paymentAnnualInterest"
          label="Annual Interest Rate (i)"
          register={register('annualInterestRate')}
          errorMessage={errors.annualInterestRate?.message}
          className="sm:col-span-2"
          trailingText="%"
        />
        <Input
          id="paymentYearsPayout"
          label="Years to Pay Out (n)"
          register={register('yearsToPayout')}
          errorMessage={errors.yearsToPayout?.message}
          className="sm:col-span-2"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-y-1 gap-x-4">
        <CalculatorResult
          title="First Annual Payment"
          fixedValue={annual?.firstPayment ?? 0}
          inflationValue={annual?.firstPaymentWithInflation ?? 0}
        />

        <CalculatorResult
          title="Last Annual Payment"
          fixedValue={annual?.lastPayment ?? 0}
          inflationValue={annual?.lastPaymentWithInflation ?? 0}
        />

        <CalculatorResult
          title="First Monthly Payment"
          fixedValue={monthly?.firstPayment ?? 0}
          inflationValue={monthly?.firstPaymentWithInflation ?? 0}
        />

        <CalculatorResult
          title="Last Monthly Payment"
          fixedValue={monthly?.lastPayment ?? 0}
          inflationValue={monthly?.lastPaymentWithInflation ?? 0}
        />
      </div>

      <div className="flex justify-end">
        <ResetButton onClick={onResetClick} />
        <CalculateButton />
      </div>
    </form>
  );
};

export default React.memo(AnnualPayment);
