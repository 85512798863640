import * as React from 'react';
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { FinancialData, RetirementData } from 'src/utils/types';
import { formatFinancial, formatName } from 'src/utils/formatter';

const tickFormatter = (value: number) => formatFinancial(value);

const formatLegend = (value: string) => formatName(value);

const labelFormatter = (value: string) => 'Age: ' + value;

const formatTooltip = (value: number | number[], name: string) => {
  if (Array.isArray(value)) {
    return [formatFinancial(value[0]) + ' ~ ' + formatFinancial(value[1]), formatName(name)];
  }
  return [formatFinancial(value), formatName(name)];
};

type Props = {
  financialData: FinancialData[];
  retirementData: RetirementData;
  currentAge: number;
};

const RetirementChart: React.FC<Props> = ({ financialData, retirementData, currentAge }) => {
  const retirementPercentageOfDuration =
    ((retirementData.desiredRetirementAge - currentAge) /
      (retirementData.desiredRetirementAge +
        retirementData.numberOfYearsOfRetirementIncome -
        currentAge -
        1)) *
    100;

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        data={financialData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
            <stop offset="0%" stopColor="red" />
            <stop offset={`${retirementPercentageOfDuration}%`} stopColor="red" />
            <stop offset={`${retirementPercentageOfDuration}%`} stopColor="#1c5a25" />
            <stop offset="100%" stopColor="#1c5a25" />
          </linearGradient>
        </defs>
        <Legend verticalAlign="top" height={36} formatter={formatLegend} />
        <XAxis dataKey="age" domain={['dataMin', 'dataMax']} interval="preserveStartEnd" />
        <YAxis
          allowDataOverflow
          allowDecimals={false}
          domain={[0, (dataMax: number) => Math.ceil(dataMax / 1000000) * 1000000]}
          interval="preserveStartEnd"
          orientation="right"
          tickFormatter={tickFormatter}
          type="number"
          width={80}
        />
        <Bar dataKey="retirementIncome" barSize={20} fill="#277e33" />
        <Line dot={false} type="monotone" dataKey="balance" stroke="url(#gradient)" />
        <Area dataKey="balanceUncertainty" stroke="#88d292" fill="#88d292" />
        <ReferenceLine x={retirementData.desiredRetirementAge} stroke="red" strokeDasharray="3 3" />
        <Tooltip formatter={formatTooltip} labelFormatter={labelFormatter} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default React.memo(RetirementChart);
