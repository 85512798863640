import * as React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import classNames from 'classnames';

const NavLinkHorizontal = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link
      to={to}
      className={classNames(
        match
          ? 'border-primary-500 text-gray-900'
          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
        'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
      )}
      aria-current={match ? 'page' : undefined}
      {...props}
    >
      {children}
    </Link>
  );
};

export default NavLinkHorizontal;
