import { calcExponential, formatDecimal, toPercentage } from './calc';
import { GetRetirementData, FinancialData } from './types';

export const getRetirementData = ({ current, retirement, uncertainty }: GetRetirementData) => {
  const annualIncomeIncreaseRate = toPercentage(current.annualIncomeIncrease);

  const annualSavingsIncreaseRate = toPercentage(current.annualSavingsIncrease);

  const investmentReturnsRate = toPercentage(current.investmentReturns);

  const annualPensionBenefitIncreaseRate = toPercentage(retirement.annualPensionBenefitIncrease);

  const incomeReplacementRate = toPercentage(retirement.incomeReplacement);

  const retiredInvestmentReturnsRate = toPercentage(retirement.investmentReturn);

  const uncertaintyInvestmentReturnRate = toPercentage(uncertainty.investmentReturn);

  const uncertaintyAnnualSavingsAmountRate = toPercentage(uncertainty.annualSavingsAmount);

  const uncertaintyAnnualSavingsIncreasesRate = toPercentage(uncertainty.annualSavingsIncreases);

  const uncertaintyAnnualPensionBenefitAmountRate = toPercentage(
    uncertainty.annualPensionBenefitAmount
  );

  const uncertaintyAnnualPensionBenefitIncreasesRate = toPercentage(
    uncertainty.annualPensionBenefitIncreases
  );

  const { numberOfYearsOfRetirementIncome, desiredRetirementAge, annualPensionBenefit } =
    retirement;

  const { currentAge, retirementSavingsBalance, annualSavingsAmount, annualIncome } = current;

  const planYears = numberOfYearsOfRetirementIncome + desiredRetirementAge - currentAge;

  const financialData: FinancialData[] = [];

  for (let year = 0; year < planYears; year++) {
    const prevYearData: FinancialData = financialData[year - 1];

    const age = currentAge + year;

    const isRetired = age >= desiredRetirementAge;

    const balance = year === 0 ? retirementSavingsBalance : prevYearData.yearEndBalance;

    const interest = isRetired
      ? balance * retiredInvestmentReturnsRate
      : balance * investmentReturnsRate;

    const savings = isRetired ? 0 : annualSavingsAmount * (1 + annualSavingsIncreaseRate) ** year;

    const retirementIncome = isRetired
      ? incomeReplacementRate * annualIncome * (1 + annualIncomeIncreaseRate) ** year
      : 0;

    const pensionIncome = isRetired
      ? annualPensionBenefit *
        (1 + annualPensionBenefitIncreaseRate) ** (age - desiredRetirementAge)
      : 0;

    const yearEndBalance = balance + interest + savings - retirementIncome + pensionIncome;

    // Uncertainty
    const balanceUncertainty = [
      year === 0 ? retirementSavingsBalance : prevYearData.yearEndBalanceUncertainty[0],
      year === 0 ? retirementSavingsBalance : prevYearData.yearEndBalanceUncertainty[1],
    ];

    const interestUncertainty = [
      isRetired
        ? balanceUncertainty[0] *
          (retiredInvestmentReturnsRate - uncertaintyInvestmentReturnRate / 2)
        : balanceUncertainty[0] * (investmentReturnsRate - uncertaintyInvestmentReturnRate / 2),
      isRetired
        ? balanceUncertainty[1] *
          (retiredInvestmentReturnsRate + uncertaintyInvestmentReturnRate / 2)
        : balanceUncertainty[1] * (investmentReturnsRate + uncertaintyInvestmentReturnRate / 2),
    ];

    const savingsUncertainty = [
      isRetired
        ? 0
        : year === 0
        ? annualSavingsAmount - (annualSavingsAmount * uncertaintyAnnualSavingsAmountRate) / 2
        : prevYearData.savingsUncertainty[0] +
          prevYearData.savingsUncertainty[0] *
            (annualSavingsIncreaseRate - uncertaintyAnnualSavingsIncreasesRate / 2),
      isRetired
        ? 0
        : year === 0
        ? annualSavingsAmount + (annualSavingsAmount * uncertaintyAnnualSavingsAmountRate) / 2
        : prevYearData.savingsUncertainty[1] +
          prevYearData.savingsUncertainty[1] *
            (annualSavingsIncreaseRate + uncertaintyAnnualSavingsIncreasesRate / 2),
    ];

    const pensionUncertainty = [
      isRetired
        ? (annualPensionBenefit -
            (annualPensionBenefit * uncertaintyAnnualPensionBenefitAmountRate) / 2) *
          (1 +
            annualPensionBenefitIncreaseRate -
            uncertaintyAnnualPensionBenefitIncreasesRate / 2) **
            (age - desiredRetirementAge)
        : 0,
      isRetired
        ? (annualPensionBenefit +
            (annualPensionBenefit * uncertaintyAnnualPensionBenefitAmountRate) / 2) *
          (1 +
            annualPensionBenefitIncreaseRate +
            uncertaintyAnnualPensionBenefitIncreasesRate / 2) **
            (age - desiredRetirementAge)
        : 0,
    ];
    const yearEndBalanceUncertainty = [
      balanceUncertainty[0] +
        interestUncertainty[0] +
        savingsUncertainty[0] -
        retirementIncome +
        pensionUncertainty[0],
      balanceUncertainty[1] +
        interestUncertainty[1] +
        savingsUncertainty[1] -
        retirementIncome +
        pensionUncertainty[1],
    ];

    financialData.push({
      age,
      salary: isRetired ? 0 : calcExponential(annualIncome, annualIncomeIncreaseRate, year),
      balance,
      balanceUncertainty,
      interest,
      interestUncertainty,
      savings,
      savingsUncertainty,
      retirementIncome,
      pensionIncome,
      pensionUncertainty,
      yearEndBalance,
      yearEndBalanceUncertainty,
    });
  }

  return financialData.map((item) => formatDecimal(item, 0)) as FinancialData[];
};
