import * as React from 'react';

type Props = {
  id: string;
  label: string;
  register?: Record<string, any>;
  errorMessage?: string;
  className?: string;
  options: Readonly<(string | number)[]>;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const Select: React.FC<Props> = ({
  id,
  label,
  register = {},
  errorMessage,
  className,
  options,
  onChange,
}) => {
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <select
          id={id}
          className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
          {...register}
          onChange={onChange}
        >
          {options.map((item) => (
            <option key={item}>{item}</option>
          ))}
        </select>
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      </div>
    </div>
  );
};

export default Select;
