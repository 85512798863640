import * as React from 'react';
import { PaymentType } from 'src/utils/types';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import AnnualPayment from './AnnualPayment';
import StartingPrincipal from './StartingPrincipal';
import { PAYMENT_TYPE_OPTIONS, getPaymentTypevalue, PaymentTypeOptions } from './AnnuityTable';
import { ErrorMessage } from './types';
import YearsToPayout from './YearsToPayout';

const AnnuityCalculator: React.FC = () => {
  const [customerName, setCustomerName] = React.useState('');
  const [paymentType, setPaymentType] = React.useState<PaymentType>(PaymentType.BEGINING_OF_PERIOD);
  const [annualInflationRate, setAnnualInflationRate] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState<ErrorMessage>({
    customerName: undefined,
    annualInflationRate: undefined,
  });

  const onSetCustomerName = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setCustomerName(e.target.value),
    []
  );

  const onPaymentTypeChange = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentType(getPaymentTypevalue(e.target.value as PaymentTypeOptions));
  }, []);

  const onSetInflationRate = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setAnnualInflationRate(e.target.value),
    []
  );

  return (
    <div className="space-y-8 divide-y divide-gray-200">
      <div className="pt-8 px-5 sm:px-0">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mb-10">
          <Input
            className="sm:col-span-4 md:col-span-3"
            label="Customer Name"
            id="customerName"
            value={customerName}
            onChange={onSetCustomerName}
            errorMessage={errorMessage.customerName}
          />
          <Select
            id="paymentType"
            label="Annuity Payment Type"
            className="md:col-span-2 sm:col-span-2"
            onChange={onPaymentTypeChange}
            options={PAYMENT_TYPE_OPTIONS}
          />
          <Input
            className="md:col-span-1 sm:col-span-2"
            label="Annual Inflation Rate (g)"
            id="annualInflationRate"
            trailingText="%"
            value={annualInflationRate}
            onChange={onSetInflationRate}
            errorMessage={errorMessage.annualInflationRate}
          />
        </div>
        <AnnualPayment
          customerName={customerName}
          paymentType={paymentType}
          annualInflationRate={parseFloat(annualInflationRate)}
          errorMessages={setErrorMessage}
        />
        <StartingPrincipal
          customerName={customerName}
          paymentType={paymentType}
          annualInflationRate={parseFloat(annualInflationRate)}
          errorMessages={setErrorMessage}
        />
        <YearsToPayout
          customerName={customerName}
          paymentType={paymentType}
          annualInflationRate={parseFloat(annualInflationRate)}
          errorMessages={setErrorMessage}
        />
      </div>
    </div>
  );
};

export default AnnuityCalculator;
